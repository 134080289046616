import React from "react"
import Layout from "../components/layout"
import { bigHeader } from "../components/Layout/contentHeader"
import Heading from "../components/Layout/heading"
import MaxWidth from "../components/Layout/maxWidth"
import SEO from "../components/seo"
import mountains from "../images/mountains.png"

export default function Vision() {
  return (
    <Layout>
      <SEO title="Vision" />
      <Heading>
        <h1>Vision</h1>
      </Heading>
      <MaxWidth>
        <h2 className={bigHeader}>
          To provide know-how for a prosperous future
        </h2>

        <p>
          When you ask people what they think about the twenties, most of them
          mention wall street crash, discovery of penicillin, jazz age,
          prohibition, or cancan dance. We are looking forward to the twenties
          of the 21st century. In just two years, this wonderful decade will
          start once again.{" "}
        </p>

        <p>
          Our vision is to provide know-how to foster a meaningful research &
          development, introduction of new technologies and their adoption for
          the market entry in twenties. Are you and your business ready for the
          twenties?
        </p>

        <img src={mountains} style={{ marginTop: "2em" }} />
      </MaxWidth>
    </Layout>
  )
}
